import React, { Component } from "react";

import { connect } from "react-redux";
import { compose } from "redux";

import _ from "lodash";
import Config from "../../../../config";
import {
  TextInput,
  Row,
  Col,
  DatePicker,
  Dropdown,
  Avatar
} from "diginet-core-ui/components";

class W09F1501EmployeeInfo extends Component {

  render() {
    const {
      getCboGenders,
      getCboProvinces,
      getCboDepartments,
      formData,
      onChange,
      isEdit,
      errorForm,
      loading,
      isView,
      upload,
    } = this.props;
    const {
      EmployeeName,
      Sex,
      Telephone,
      NumIDCard,
      NumIDCardDate,
      NumIDCardPlace,
      IDCard,
      IDCardDate,
      IDCardPlace,
      ContactAddress,
      DepartmentID,
      UserPictureURL
    } = formData;
    const isEmptyNumIDCard = _.isEmpty(NumIDCard);
    const isEmptyIDCard = _.isEmpty(IDCard);
    const iEmptySex = _.isEmpty(Sex);
    return (
        <Row>
          <Col xs={9} sm={9} md={10} lg={10}>
            <Row>
              <Col xs={12}>
                <TextInput
                  error={errorForm && errorForm["EmployeeName"]}
                  required={!isView}
                  label={Config.lang("DHR_Ho_va_ten")}
                  viewType={"underlined"}
                  value={EmployeeName}
                  disabled={isEdit || isView}
                  onChange={e => onChange(e, "EmployeeName")}
                />
              </Col>
              <Col xs={12}>
                <Dropdown
                  required={!isView}
                  viewType={"underlined"}
                  label={Config.lang("DHR_Gioi_tinh")}
                  dataSource={getCboGenders}
                  displayExpr={"SexName"}
                  valueExpr={"Sex"}
                  onChange={e => onChange(e, "Sex")}
                  value={Sex}
                  disabled={isEdit || isView}
                  error={iEmptySex && _.get(errorForm, "Sex", false)}
                />
              </Col>
              <Col xs={12}>
                <TextInput
                  label={Config.lang("DHR_So_dien_thoai")}
                  viewType={"underlined"}
                  disabled={isView}
                  type={"number"}
                  value={Telephone || ""}
                  inputProps={{ min: 0 }}
                  onChange={e => onChange(e, "Telephone")}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={3} sm={3} md={2} lg={2} className={"display_row align-center valign-middle"}>
            <Avatar
              disabled={loading}
              onClick={upload}
              allowEdit
              width={125}
              height={125}
              src={
                !_.isEmpty(UserPictureURL)
                  ? UserPictureURL
                  : require("../../../../assets/images/general/user_default.svg")
              }
            />
          </Col>
          <Col xs={12}>
            <Dropdown
              disabled={loading || isView}
              viewType={"underlined"}
              label={Config.lang("DHR_Phong_ban")}
              dataSource={getCboDepartments}
              displayExpr={"DepartmentName"}
              valueExpr={"DepartmentID"}
              onChange={e => onChange(e, "DepartmentID")}
              clearAble
              value={DepartmentID}
            />
          </Col>
          <Col xs={12} md={6}>
            <TextInput
              error={isEmptyIDCard && _.get(errorForm, "NumIDCard", false)}
              label={Config.lang("DHR_Chung_minh_nhan_dan")}
              viewType={"underlined"}
              disabled={isView}
              type={"number"}
              value={NumIDCard || ""}
              inputProps={{ min: 0 }}
              onChange={e => onChange(e, "NumIDCard")}
              required={isEmptyIDCard}
            />
          </Col>
          <Col xs={12} md={3}>
            <DatePicker
              height={30}
              label={Config.lang("DHR_Ngay_cap")}
              width={"100%"}
              viewType={"underlined"}
              value={NumIDCardDate}
              onChange={e => onChange(e, "NumIDCardDate")}
              error={!isEmptyNumIDCard &&_.get(errorForm, "NumIDCardDate", false)}
              disabled={isEmptyNumIDCard || loading}
              required={!isEmptyNumIDCard}
              clearAble
            />
          </Col>
          <Col xs={12} md={3}>
            <Dropdown
              disabled={isEmptyNumIDCard || loading}
              error={!isEmptyNumIDCard &&_.get(errorForm, "NumIDCardPlace", false)}
              viewType={"underlined"}
              label={Config.lang("DHR_Noi_cap")}
              dataSource={getCboProvinces}
              displayExpr={"PopulationName"}
              valueExpr={"PopulationID"}
              onChange={e => onChange(e, "NumIDCardPlace")}
              clearAble
              value={NumIDCardPlace}
              required={!isEmptyNumIDCard}
            />
          </Col>
          <Col xs={12} md={6}>
            <TextInput
              error={isEmptyNumIDCard && _.get(errorForm, "IDCard", false) }
              type={"number"}
              label={Config.lang("DHR_The_can_cuoc")}
              viewType={"underlined"}
              disabled={isView}
              value={IDCard}
              inputProps={{ min: 0 }}
              onChange={e => onChange(e, "IDCard")}
              required={isEmptyNumIDCard}
            />
          </Col>
          <Col md={3} xs={12}>
            <DatePicker
              error={!isEmptyIDCard && _.get(errorForm, "IDCardDate", false)}
              height={30}
              label={Config.lang("DHR_Ngay_cap")}
              viewType={"underlined"}
              value={IDCardDate}
              onChange={e => onChange(e, "IDCardDate")}
              disabled={isEmptyIDCard || loading}
              required={!isEmptyIDCard}
              clearAble
            />
          </Col>
          <Col md={3} xs={12}>
            <Dropdown
              error={!isEmptyIDCard && _.get(errorForm, "IDCardPlace", false)}
              viewType={"underlined"}
              label={Config.lang("DHR_Noi_cap")}
              dataSource={getCboProvinces}
              displayExpr={"PopulationName"}
              valueExpr={"PopulationID"}
              onChange={e => onChange(e, "IDCardPlace")}
              clearAble
              value={IDCardPlace}
              disabled={isEmptyIDCard || loading}
              required={!isEmptyIDCard}
            />
          </Col>
          <Col xs={12}>
            <TextInput
              disabled={isView}
              onChange={e => onChange(e, "ContactAddress")}
              label={Config.lang("DHR_Dia_chi")}
              viewType={"underlined"}             
              value={ContactAddress}
              inputProps={{ min: 0 }}
            />
          </Col>
        </Row>
    );
  }
}

export default compose(
  connect(state => ({
    getCboGenders: state.W09F1501.getCboGenders,
    getCboProvinces: state.W09F1501.getCboProvinces,
    getCboDepartments: state.general.getCboDepartments
  })),
)(React.forwardRef((props, ref) => <W09F1501EmployeeInfo innerRef={ref} {...props} />));