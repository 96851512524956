/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 1/8/2020
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup, Image} from "react-bootstrap"
import withStyles from "@material-ui/core/styles/withStyles";
import Approvals from "../../../approvals/approvals";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import LabelText from "../../../common/label-text/label-text";
import InlineSVG from "react-inlinesvg";
import {Input} from "@material-ui/core";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import * as generalActions from "../../../../redux/general/general_actions";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        width: '100%',
        height: 'auto',
        objectFit: 'contain'
    },
};
class W09F1000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            iPermission: 0,
            dataForm: {},
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F1000", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission) return;
        this.checkNotify();
    };

    UNSAFE_componentWillReceiveProps = () => {
        this.checkNotify();
    };

    checkNotify = () => {
        const {VoucherID, ApprovalFlowID, ApprovalLevel} = this.getInfo();
        if(VoucherID && !this.voucher_id){
            this.voucher_id = VoucherID;
            this.loadFormInfo(VoucherID,(data)=>{
                this.voucher_id=null;
                data.VoucherID=VoucherID;
                data.ApprovalFlowID=ApprovalFlowID;
                data.ApprovalLevel=ApprovalLevel;
                this.refs['Approvals'].showPopup(data);
            });
        }
    };

    getInfo = () => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('voucher_id')) {
            return {
                VoucherID: url.get('voucher_id'),
                ApprovalFlowID: url.get('approval_flow_id'),
                ApprovalLevel: url.get('approval_level')
            };
        } else if (location && location.state) {
            return {
                VoucherID: location.state.voucher_id,
                ApprovalFlowID: location.state.approval_flow_id,
                ApprovalLevel: location.state.approval_level,
            }
        } else {
            return {};
        }
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W09F1000",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.setState({loading: true});
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };

    //Form info..
    renderFormInfo = (data) => {
        const {classes} = this.props;
        return (
            <>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("Nhan_vien")}
                                value={() => {
                                    return (
                                        <div className={"display_row align-center pdb5"} style={{width: "100%", height: 37}}>
                                            <div className={classes.divAvatar}>
                                                {data && data.UserPictureURL ? (
                                                    <Image className={classes.imgAvatar} src={data.UserPictureURL.indexOf('http') < 0 ? Config.getCDNPath() + data.UserPictureURL : data.UserPictureURL}/>
                                                ) : (
                                                    <InlineSVG className={classes.imgAvatar}
                                                               src={require('../../../../assets/images/general/user_default.svg')}/>
                                                )}
                                            </div>
                                            <Input
                                                color={"primary"}
                                                readOnly={true}
                                                value={data && data.EmployeeName ? data.EmployeeName : ""}
                                                disableUnderline={true}
                                            />
                                            {/*<div style={{padding: '7px 0'}}>{data && data.EmployeeName ? data.EmployeeName : ""}</div>*/}
                                        </div>
                                    );
                                }}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("Ma_nhan_vien")}
                                value={Config.helpers.getObjectValue(data, 'EmployeeID', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("Chuc_vu")}
                                value={Config.helpers.getObjectValue(data, 'DutyName', '')}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("Ngay_vao_lam")}
                                value={Config.helpers.convertDate(Config.helpers.getObjectValue(data, 'DateJoined', null), "")}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("Chung_minh_nhan_dan")}
                                value={Config.helpers.getObjectValue(data, 'NumIDCard', '')}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("The_can_cuoc")}
                                value={Config.helpers.getObjectValue(data, 'IDCard', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("So_dien_thoai")}
                                value={Config.helpers.getObjectValue(data, 'Telephone', '')}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("Gioi_tinh")}
                                value={Config.helpers.getObjectValue(data, 'SexName', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <LabelText
                        label={Config.lang("DHR_Du_an")}
                        value={Config.helpers.getObjectValue(data, 'ProjectName', '')}
                        fullWidth={true}
                    />
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("Ngay_sinh")}
                                value={Config.helpers.getObjectValue(data, 'BirthDate', '')}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                label={Config.lang("Nghiep_vu")}
                                value={Config.helpers.getObjectValue(data, 'TransactionName', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </>
        );
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    render() {
        const {loading, iPermission,dataForm, VoucherID} = this.state;
        if (iPermission <= 0) return null;


        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("Xac_nhan_nhan_vien_nhan_viec_tai_du_an")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                loading={loading}
                                FormID={"W09F1000"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                disabled={loading}
                                selectedRowKey={VoucherID}
                                labelBtnApprove={Config.lang("Xac_nhan")}
                                labelBtnCancelApprove={Config.lang("Bo_xac_nhan")}
                                singleClick
                                formInfoRender={this.renderFormInfo} //render form info with every screen
                                onSelectionChanged={this.onSelectionChanged} //action when select row...
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect((state) => ({
    iPermission: state.general.iPermission
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    approvalActions: bindActionCreators(ApprovalActions, dispatch),
})), withStyles(styles))(W09F1000);
