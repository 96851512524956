import React, { Component } from "react";

import { connect } from "react-redux";
import {bindActionCreators, compose} from "redux";

import _ from "lodash";

import Config from "../../../../config/index";
import {
  TextInput,
  Row,
  Col,
  DatePicker,
  Button,
  Dropdown,
  Attachment
} from 'diginet-core-ui/components';
import * as generalActions from "../../../../redux/general/general_actions";
class W09F1501OrgRec extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCboProjects: {
                rows: [],
                total: 0
            },
            loading: {
                cboProjects: false
            }
        };
        this.loading = {
            cboProjects: false
        };
        this.filterCboProject = {
            strSearch: "",
            skip: 0,
            limit: 20
        };
    }

    loadCboProjects = (isReset) => {
        const params = {
            HostID: "",
            FormID: "W29F3000",
            Language: Config.language || "84",
            skip: this.filterCboProject.skip,
            limit: this.filterCboProject.limit,
            search: this.filterCboProject.strSearch
        };
        this.loading.cboProjects = true;
        this.setState({loading: this.loading});
        this.props.generalActions.getCboProjects(params, (errors, data) => {
            this.loading.cboProjects = false;
            this.setState({loading: this.loading});
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data.rows ? data.rows : data;
                const total = data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    },
                });
            }
        });
    };

    componentDidMount() {
        this.loadCboProjects();
    }

  render() {
    const {
      getCboDirectManagers,
      getCboPositions,
      formData,
      onChange,
      onChangeAttachments,
      dataOldAttachments,
      loading,
      isView,
      errorForm,
      onSave
    } = this.props;
    const { DirectManagerID, DateJoined, DutyID, ProjectID, Note } = formData;
    const {dataCboProjects} = this.state;
    return (
      <div style={{ width: "100%", marginTop:16 }}>
        <Row>
          <Col xs={12} md={6}>
            <Dropdown
              disabled={loading || isView}
              viewType={"underlined"}
              label={Config.lang("DHR_Nguoi_quan_ly_truc_tiep")}
              dataSource={getCboDirectManagers}
              displayExpr={"DirectManagerName"}
              valueExpr={"DirectManagerID"}
              onChange={e => onChange(e, "DirectManagerID")}
              clearAble
              value={DirectManagerID}
            />
          </Col>
          <Col xs={12} md={6}>
            <DatePicker
              clearAble
              height={30}
              required={!isView && true}
              label={Config.lang("DHR_Ngay_vao_lam")}
              width={"100%"}
              viewType={"underlined"}
              value={DateJoined}
              onChange={e => onChange(e, "DateJoined")}
              disabled={isView || loading}
              error={_.get(errorForm, "DateJoined", false)}
            />
          </Col>
          <Col xs={12} md={6}>
            <Dropdown
              disabled={loading || isView}
              viewType={"underlined"}
              label={Config.lang("DHR_Chuc_vu")}
              dataSource={getCboPositions}
              displayExpr={"DutyName"}
              valueExpr={"DutyID"}
              onChange={e => onChange(e, "DutyID")}
              clearAble
              value={DutyID}
            />
          </Col>
          <Col xs={12} md={6}>
              <Dropdown
                dataSource={dataCboProjects?.rows}
                skip={this.filterCboProject.skip}
                limit={this.filterCboProject.limit}
                displayExpr={'{ProjectID} - {ProjectName}'}
                valueExpr={'ProjectID'}
                value={ProjectID}
                loading={this.state.loading.cboProjects}
                viewType={'underlined'}
                label={Config.lang('DHR_Du_an')}
                disabled={loading || isView}
                clearAble
                onChange={e => onChange(e, "ProjectID")}
                onInput={(e) => {
                    this.filterCboProject.strSearch = e.target.value;
                    this.filterCboProject.skip = 0;
                    this.loadCboProjects(true);
                }}
                onLoadMore={(e) => {
                    this.filterCboProject.skip = e.skip;
                    this.filterCboProject.limit = e.limit;
                    this.loadCboProjects();
                }}
              />
          </Col>
          <Col xs={12}>
            <TextInput
              label={Config.lang("DHR_Ghi_chu")}
              viewType={"underlined"} 
              disabled={isView || loading}
              value={Note}
              inputProps={{ min: 0 }}
              onChange={e => onChange(e, "Note")}
            />
          </Col>
            <Col xs={12}>
              <Attachment
                ref={ref => (this.attRef = ref)}
                data={dataOldAttachments}
                disabled={isView || loading}
                maxFile={5}
                onChanged={onChangeAttachments}
                uploadErrorInfo={{
                  existingFile: Config.lang('File_da_duoc_dinh_kem'),
                  maxFile     : Config.lang('File_vuot_qua_so_luong_cho_phep'),
                  maxSize     : Config.lang('File_vuot_qua_dung_luong_cho_phep'),
                  fileType    : Config.lang("File_khong_dung_dinh_dang"),
                }}
              />
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                text={Config.lang("DHR_Luu")}
                startIcon={"save"}
                disabled={isView || loading}
                style={{ textTransform: "uppercase" }}
                size={"medium"}
                onClick={onSave}
                viewType="filled"
                color="info"
                />
            </Col>
          </Row>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    getCboPositions: state.W09F1501.getCboPositions,
    getCboDirectManagers: state.W09F1501.getCboDirectManagers,
    getCboProvinces: state.W09F1501.getCboProvinces,
    getAttachmentsByTransID: state.general.getAttachmentsByTransID
  }),
      dispatch => ({
          generalActions: bindActionCreators(generalActions, dispatch),
      })
  ),
)(W09F1501OrgRec);