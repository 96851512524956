/**
 * @copyright 2019 @ DigiNet
 * @author THIENPHAM
 * @create 2/4/2020
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup, Image} from "react-bootstrap"
import withStyles from "@material-ui/core/styles/withStyles";
import Approvals from "../../../approvals/approvals";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import LabelText from "../../../common/label-text/label-text";
import InlineSVG from "react-inlinesvg";
import { Input } from "@material-ui/core";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import * as generalActions from "../../../../redux/general/general_actions";
import moment from "moment";
const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        width: '100%',
        height: 'auto',
        objectFit: 'contain'
    },
};
class W09F1005 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataForm: {},
            VoucherID: "",
            iPermission: 0,
            loading: false
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F1005", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W09F1005",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.setState({loading: true})
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            this.setState({loading: false})
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.checkNotify();
    };

    UNSAFE_componentWillReceiveProps = () => {
        this.checkNotify();
    };

    checkNotify = () => {
        const {VoucherID} = this.getInfo();
        this.setState({VoucherID: VoucherID});
        // if(VoucherID && !this.voucher_id){
        //     this.voucher_id = VoucherID;
        //     this.loadFormInfo(VoucherID,(data)=>{
        //         this.voucher_id=null;
        //         data.VoucherID=VoucherID;
        //         this.refs['Approvals'].showPopup(data);
        //     });
        // }
    };

    getInfo = () => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('voucher_id')) {
            return {VoucherID: url.get('voucher_id')};
        } else if (location && location.state) {
            return {
                VoucherID: location.state.voucher_id,
            }
        } else {
            return {};
        }
    };

    //Form info..
    renderFormInfo = (data) => {
        const {classes} = this.props;
        return (
            <>
                <FormGroup>
                <Row>
                <Col xs={12} sm={12} md={6} lg={6} className={"mgb0"}>
                    <LabelText
                        fullWidth={true}
                        label={Config.lang("DHR_Nhan_vien")}
                        allowPadding={true}
                        value={() => {
                            return (
                                <div className={"display_row align-center pdb5"} style={{width: "100%", height: 37}}>
                                    <div className={classes.divAvatar}>
                                        {data && data.UserPictureURL ? (
                                            <Image className={classes.imgAvatar} src={data.UserPictureURL.indexOf('http') < 0 ? Config.getCDNPath() + data.UserPictureURL : data.UserPictureURL}/>
                                        ) : (
                                            <InlineSVG className={classes.imgAvatar}
                                                       src={require('../../../../assets/images/general/user_default.svg')}/>
                                        )}
                                    </div>
                                    <Input
                                        color={"primary"}
                                        readOnly={true}
                                        value={data && data.EmployeeName ? data.EmployeeName : ""}
                                        disableUnderline={true}
                                    />
                                    {/* <div style={{padding: '7px 0'}}>{data && data.EmployeeName ? data.EmployeeName : ""}</div> */}
                                </div>
                            );
                        }}
                    />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} className={"mgb0"}>
                    <LabelText
                        fullWidth={true}
                        label={Config.lang("DHR_Ma_nhan_vien")}
                        allowPadding={true}
                        style={{paddingTop: 7}}
                        value={() => {
                            return (
                                    <Input
                                        color={"primary"}
                                        readOnly={true}
                                        value={data.EmployeeID}
                                        disableUnderline={true}
                                />
                            );
                        }}
                    />
                    </Col>
                </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ma_hop_dong")}
                                value={Config.helpers.getObjectValue(data, 'LaborContractNo', '')}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Loai_hop_dong")}
                                value={Config.helpers.getObjectValue(data, 'WorkFormName', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ngay_bat_dau")}
                                value={moment.utc(data.ContractDateBegin).format("DD/MM/YYYY") || ""}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ngay_ket_thuc")}
                                value={moment.utc(data.ContractDateEnd).format("DD/MM/YYYY") || ""}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Nguoi_dai_dien")}
                                value={Config.helpers.getObjectValue(data, 'RepresentationalAName', '')}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Chuc_vu")}
                                value={Config.helpers.getObjectValue(data, 'DutyName', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>                    
                    <LabelText
                        allowPadding={true}
                        label={Config.lang("DHR_Ghi_chu")}
                        value={Config.helpers.getObjectValue(data, 'Note', '')}
                        fullWidth={true}
                    />
                </FormGroup>
            </>
        );
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    render() {
        const {iPermission, dataForm, VoucherID, loading} = this.state;
        if (iPermission <= 0) return null;
        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Cap_nhat_trang_thai_hop_dong_lao_dong")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                allowAddingAttachment
                                labelBtnApprove={Config.lang("DHR_Xac_nhan")}
                                labelBtnCancelApprove={Config.lang("DHR_Bo_xac_nhan")}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                FormID={"W09f1005"}
                                selectedRowKey={VoucherID}
                                loading={loading}
                                singleClick
                                formInfoRender={this.renderFormInfo} //render form info with every screen
                                onSelectionChanged={this.onSelectionChanged} //action when select row...
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect(state =>({ getAttachments: state.approvals.getAttachments}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    approvalActions: bindActionCreators(ApprovalActions, dispatch),
})), withStyles(styles))(W09F1005);
