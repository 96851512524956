/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/23/2019
 * @Example
 */

import React from "react";
import Config from "../../../../config";
import * as w09f2000Actions from "../../../../redux/W0X/W09F2000/W09F2000_actions";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import AttachmentPopup from "../../../attachments/attachment-popup";

const styles = {
    root: {
        minWidth: '200px',
        width: '20%',
        height: '48px'
    },
};
class W09F0001Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAttactmentPopup: true,
        };
    }

    onClosePopup = () => {
        this.setState({
            showAttactmentPopup: false
        });
    };

    render() {
        const {showAttactmentPopup} = this.state;
        const data = {
            KeyID: 'abc',
            FormID: 'W09F2060',
            TableName: 'D09T2151'
        };

        return (
            <div>
                <AttachmentPopup
                    data={data}
                    open={showAttactmentPopup}
                    title={Config.lang('DHR_Dinh_kem')}
                    onClose={this.onClosePopup}
                />
            </div>
        );
    }
}

export default compose(connect((state) => ({
        getMasterData: state.W09F2000.getMasterData
    }),
    (dispatch) => ({
        w09f2000Actions: bindActionCreators(w09f2000Actions, dispatch),
    })
), withStyles(styles))(W09F0001Popup);
