/**
 * @copyright 2023 @ DigiNet
 * @author TRNGHOANG
 * @create 08/03/2023
 * @Example
 */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import * as W09F1121Actions from "../../../../redux/W0X/W09F1121/W09F1121_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Checkbox,
    Dropdown,
    DateRangePicker,
    Divider,
    ButtonIcon,
    Chip,
    Row,
    Col,
    Popover,
    PopoverBody,
    Icon,
    Typography,
    ModalFooter,
} from "diginet-core-ui/components";
import { makeStyles, useTheme } from "diginet-core-ui/theme";
import CDN from "../../../../components/CDN";
import ChooseModal from "../../../popup/popup-choose";
import HeadClick from "../../../../components/grid-container/head-click";
import { Loading } from "../../../common/loading/loading";
const { spacing, colors, zIndex } = useTheme();

const useStyle = makeStyles(() => ({
    animation: {
        transition: "all 1s",
    },
    noWrap: {
        whiteSpace: "pre",
    },
    leftBox: {
        paddingRight: spacing(4),
        position: "relative",
        transition: "all 1s",
        overflow: "hidden",
    },
    divider: {
        position: "absolute",
        right: 0,
        top: 0,
        width: 1,
        height: "100%",
    },
}));

const W09F1121 = props => {
    const { open, Ancestors = [], onClose } = props;

    const [loading, setLoading] = useState(false);
    const [minimum, setMinimum] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isShowSelected, setIsShowSelected] = useState(false);
    const [dataOrg, setDataOrg] = useState([]);
    const [dataTemplate, setDataTemplate] = useState([]);
    const [dataGrid, setDataGrid] = useState({ rows: [], total: 0 });
    const [dataProject, setDataProject] = useState({ rows: [], total: 0 });
    const [cboOrgLoading, setCboOrgLoading] = useState(false);
    const [cboProjectLoading, setCboProjectLoading] = useState(false);
    const [cboEmployeeLoading, setCboEmployeeLoading] = useState(false);
    const [dataCboEmployee, setDataCboEmployee] = useState({ rows: [], total: 0 });
    const [popoverAttachment, setPopoverAttachment] = useState(null);
    const [showRepport, setShowReport] = useState(false);

    const gridRef = useRef(null);
    const attachmentRef = useRef([]);
    const popoverRef = useRef(null);
    const chipRef = useRef([]);
    const selectedRange = useRef();

    const isSelecting = useRef(false);
    const tmpSelectedRowKeys = useRef([]);
    const tmpCurrentSelectedRowKeys = useRef([]);
    const tmpSelectedRowData = useRef([]);
    const tmpDataSelectedRowKeys = useRef([]);

    const changePage = useRef(false);
    const isChangeShow = useRef(false);
    const filterSearch = useRef({
        skip: 0,
        limit: 10,
    });
    const filterCboEmployees = useRef({
        skip: 0,
        limit: 50,
    });
    const filterCboProjects = useRef({
        skip: 0,
        limit: 50,
    });

    const dispatch = useDispatch();

    const classes = useStyle();

    useEffect(() => {
        loadCboEmployees();
        loadDataOrgChart();
        loadCboProjects();
        loadCboTemplate();
        loadGrid(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * đóng popover view attachment khi remove hết đính kèm trong popover (popoverAttachment?.EmployeeID = null)
     */
    useEffect(() => {
        if (popoverAttachment?.EmployeeID) {
            popoverRef.current.show();
        } else if (popoverRef.current?.close instanceof Function) popoverRef.current.close();
    }, [popoverAttachment]);

    /**
     * Func thay đổi data filter
     * @param key
     * @param evt
     * @param type
     */
    const filterChange = (key, evt, type) => {
        if (!key || !evt) return;
        const value = evt?.value ?? evt?.target?.value ?? "";
        if (type === "Date") {
            const dateFrom = Array.isArray(value) ? value[0] : null;
            const dateTo = Array.isArray(value) ? value[1] : null;
            filterSearch.current = {
                ...filterSearch.current,
                [`${key}From`]: dateFrom,
                [`${key}To`]: dateTo,
            };
        } else {
            filterSearch.current = { ...filterSearch.current, [key]: value };
        }
    };

    /**
     * load data dropdown nhân viên bên thanh filter trái
     * @param isReset
     */
    const loadCboEmployees = isReset => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W09F1120",
            Language: Config.language || "84",
            ...filterCboEmployees.current,
        };

        setCboEmployeeLoading(true);
        dispatch(
            generalActions.getCboEmployees(param, (error, data) => {
                setCboEmployeeLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                }
                const rows = data?.rows || data || [];
                const total = data.total || data.length || 0;
                setDataCboEmployee({
                    rows: isReset ? rows : dataCboEmployee.rows.concat(rows),
                    total: total,
                });
            })
        );
    };

    /**
     * load data dropdown cơ cấu tổ chức bên thanh filter trái
     */
    const loadDataOrgChart = () => {
        setCboOrgLoading(true);
        dispatch(
            generalActions.getOrgCharts({}, (error, data) => {
                setCboOrgLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                }
                setDataOrg(data || []);
            })
        );
    };

    /**
     * load data dropdown dự án bên thanh filter trái
     * @param isReset
     */
    const loadCboProjects = isReset => {
        const param = {
            HostID: "",
            FormID: "W09F1120",
            ...filterCboProjects.current,
        };
        setCboProjectLoading(true);
        dispatch(
            generalActions.getCboProjects(param, (error, data) => {
                setCboProjectLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                }
                if (data) {
                    const rows = data && data.rows ? data.rows : data;
                    const total = data && data.total ? data.total : data.length;
                    setDataProject({
                        rows: isReset ? rows : dataProject.rows.concat(rows),
                        total: total,
                    });
                }
            })
        );
    };

    /**
     * load data combo template trong lưới
     */
    const loadCboTemplate = () => {
        dispatch(
            W09F1121Actions.loadTemplate({}, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                } else {
                    setDataTemplate(data);
                }
            })
        );
    };

    /**
     * load data lưới chính
     */
    const loadGrid = (isReset) => {
        const params = {
            ...filterSearch.current,
        };
        if (!isReset) {
            changePage.current = true;
        }
        setLoading(true);
        dispatch(
            W09F1121Actions.loadGrid(params, (error, data) => {
                setLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                }
                const temp = data?.rows.map(i => ({ ...i, ReportID: [], Reports: [], IsWord: 0, IsExcel: 0, IsPDF : 0 })) || [];
                let selectedRowKeys = tmpSelectedRowKeys.current;
                setDataGrid({total: data?.total, rows: temp});
                setTimeout(()=> {
                    setSelectedRowKeys(selectedRowKeys);
                },300)
            })
        );
    };

    /**
     * Render Icon đại diện cho loại file
     * @param file
     * @return {*}
     */
    const renderIcon = file => {
        let ext = file ? file.split(".") : "";
        ext = ext[ext.length - 1];
        let name = "";
        let color = "";
        switch (ext) {
            case "jpeg":
            case "jpg":
            case "png":
            case "gif":
            case "bmp":
                name = "Image";
                color = "dynamic/pumpkin";
                break;

            case "doc":
            case "docx":
            case "txt":
                name = "Word";
                color = "primary";
                break;

            case "xls":
            case "xlsx":
                name = "Sheet";
                color = "success";
                break;

            case "ppt":
            case "pptx":
                name = "Presentation";
                color = "danger";
                break;

            case "pdf":
                name = "Pdf";
                color = "danger";
                break;

            default:
                name = "Another";
                break;
        }
        return <Icon color={color} name={name} width={spacing(5)} height={spacing(5)} />;
    };

    /**
     * convert data file attachment
     * @param file
     * @return {Array}
     * @private
     */
    const _getAttachments = file => {
        const dataFile = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split(".").pop() : "",
            });
        });

        return arrAttachment;
    };

    /**
     * Confirm danh sách nhân viên được chọn phải có đính kèm và mẫu báo cáo
     */
    const confirmSave = () => {
        let dataSelected = gridRef.current.instance.getSelectedRowKeys();
        if (!dataSelected?.length) {
            Config.popup.show("WARNING", Config.lang("Ban_chua_chon_nhan_vien"));
            return;
        }
        dataSelected = dataGrid.rows.filter(i => dataSelected.includes(i.EmployeeID));
        const checkValid = dataSelected.filter(i => (!i.Attachments?.length && !i.ReportID?.length));
        if (!checkValid.length) {
            onSave();
        } else {
            Config.popup.show(
                "WARNING",
                `${Config.lang("Ton_tai_nhan_vien_chua_co_dinh_kem_hoac_mau_ho_so")}: ${checkValid.map(
                    i => `<br/> - ${i.EmployeeID} - ${i.EmployeeName}`
                )}`
            );
        }
    };

    /**
     * thực thi lưu data cả lưới
     * lưu theo từng dòng: lưu CDN đính kèm thành công => tiếp theo sẽ lưu 1 dòng trên lưới
     * @type {Function}
     */
    const onSave = useCallback(async () => {
        setLoading(true);
        let dataSelected = gridRef.current.instance.getSelectedRowKeys();
        dataSelected = dataGrid.rows.filter(i => dataSelected.includes(i.EmployeeID));
        for (const index in dataSelected) {
            dataSelected[index].arrTemplate = dataSelected[index].Reports;
            if (dataSelected[index].Attachments?.length) {
                const att = await CDN.uploadFileSync(dataSelected[index].Attachments);
                if (att.data) {
                    const arrAttachments = _getAttachments(att);
                    dataSelected[index].arrAttachment = arrAttachments;
                    delete dataSelected[index].Attachments;
                } else {
                    Config.popup.show("ERROR", `Đính kèm cho nhân viên ${dataSelected[index].EmployeeID} lỗi`);
                    dataSelected[index].arrAttachment = [];
                }
            }
            delete dataSelected[index].ReportID;
            delete dataSelected[index].Reports;
            delete dataSelected[index].Attachments;
        }
        const params = {
            ancestors: Ancestors,
            arrEmployee: dataSelected,
        };
        dispatch(
            W09F1121Actions.save(params, err => {
                setLoading(false);
                if (err) Config.popup.show("ERROR", err);
                else {
                    Config.notify.show("success", "Luu_thanh_cong");
                    onClose && onClose();
                }
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid]);

    /**
     * Func sau khi chọn xong Mẫu báo cáo sẽ gán giá trị đã chọn vào data grid
     * @param e
     */
    const onChoosedReport = e => {
        const { selectedRowsData = [], selectedRowKeys } = e || {};
        const rowIndex = gridRef.current.instance.getRowIndexByKey(showRepport);
        gridRef.current.instance.cellValue(rowIndex, "ReportID", selectedRowKeys);
        gridRef.current.instance.cellValue(rowIndex, "Reports", selectedRowsData);
        gridRef.current.instance.saveEditData();
    };

    /**
     * Func khi nhắn icon đính kèm, lưu local data vào danh sách lưới vs field là Attachments
     * @param e
     */
    const onChangeAttachment = (e, data) => {

        const rowIndex = gridRef.current.instance.getRowIndexByKey(data.EmployeeID);
        const oldData = gridRef.current.instance.cellValue(rowIndex, "Attachments") || [];
        gridRef.current.instance.cellValue(rowIndex, "Attachments", [...oldData, ...e.target.files]);
        gridRef.current.instance.saveEditData();
    };

    /**
     * Xử lý khi bấm remove file đính kèm
     */
    const removeAtt = (idx, data = popoverAttachment) => {
        const rowIndex = gridRef.current.instance.getRowIndexByKey(data.EmployeeID);
        const oldData = gridRef.current.instance.cellValue(rowIndex, "Attachments") || [];
        oldData.splice(idx, 1);
        gridRef.current.instance.cellValue(rowIndex, "Attachments", [...oldData]);
        gridRef.current.instance.saveEditData();
        setPopoverAttachment({...popoverAttachment, attachments: oldData});
        // Nếu xoá hết đính kèm trong popover thì đóng popover
        if (oldData.length <= 1) {
            popoverRef.current.close();
        }
    };

    const renderHeadClickColumn = e => {
        if (!e) return false;

        return (
            <HeadClick
                selectedRange={selectedRange.current}
                dataHeaderCell={e}
                allowClick={true}
                isGroup={true}
                isCopyAll={true}
                isAllData={true}
            />
        );
    };

    const renderCheckbox = (e, fieldName) => {
        const {rowIndex} = e;
        return (
            <div className="display_row align-center valign-middle">
                <Checkbox
                    checked={e.data[fieldName] === 1}
                    onChange={(chk) => {
                        gridRef.current.instance.cellValue(rowIndex, fieldName, chk.target.checked ? 1 : 0);
                        gridRef.current.instance.saveEditData();
                    }}
                />
            </div>
        )
    }

    const handleChangePage = (page) => {
        filterSearch.current.skip = page * filterSearch.current.limit;
        loadGrid();
    };

    const handleChangePerPage = (perPage) => {
        filterSearch.current.skip = 0;
        filterSearch.current.limit = perPage;
        loadGrid();
    };

    const search = () => {
        filterSearch.current.skip = 0;
        loadGrid();
    };

    const setSelectedEmployees = (e) => {
        const currentSelectedRowKeys = e.currentSelectedRowKeys;
        const currentSelectedRowData = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (currentDeselectedRowKeys.length > 0) {
            if (changePage.current) {
                changePage.current = false;
                return false;
            }
            if (isChangeShow.current) {
                isChangeShow.current = false;
                return false;
            }

            tmpSelectedRowData.current = tmpSelectedRowData.current.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e.EmployeeID) < 0;
            });
            tmpSelectedRowKeys.current = tmpSelectedRowKeys.current.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
            tmpDataSelectedRowKeys.current = tmpDataSelectedRowKeys.current.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
        } else if (currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.forEach((val) => {
                if (tmpSelectedRowKeys.current.indexOf(val) < 0) {
                    tmpSelectedRowKeys.current.push(val);
                    const data = currentSelectedRowData.find(d => d.EmployeeID === val);
                    if (data) tmpSelectedRowData.current.push(data);
                }

                if (tmpDataSelectedRowKeys.current.indexOf(val) < 0) {
                    tmpDataSelectedRowKeys.current.push(val);
                }
            });
        }
    };

    const onSelectionChanged = async (e) => {
        if (!isSelecting.current) {
            isSelecting.current = true;
            await setSelectedEmployees(e);
            setSelectedRowKeys(e.selectedRowKeys);
            isSelecting.current = false;
            changePage.current = false;
            isChangeShow.current = false;
        }
    };

    const changeTypeShow = (e) => {
        if (!e) return false;
        isChangeShow.current = true;
        const value = e?.value ?? false;
        setIsShowSelected(value);
        let selectedRowKeys = tmpSelectedRowKeys.current;
        setSelectedRowKeys(selectedRowKeys);
    };

    //true: Con key chua duoc select; false: da select het..
    const _checkSelectedRowsKeys = (dataSource) => {
        let isCheck = false;
        if (dataSource && dataSource.length > 0 && tmpSelectedRowKeys.current?.length > 0) {
            const dataKeys = dataSource.map(d => d.EmployeeID);
            for (let key of tmpSelectedRowKeys.current) {
                if (!dataKeys.includes(key)) {
                    isCheck = true;
                    break;
                }
            }
        }
        return isCheck;
    };


    let _dataGrid = dataGrid.rows;
    let _selectedRowKeys = selectedRowKeys;
    if (isShowSelected) {
        tmpCurrentSelectedRowKeys.current = selectedRowKeys;
        if (tmpSelectedRowData.current) {
            _dataGrid = tmpSelectedRowData.current;
            if (_selectedRowKeys.length === tmpCurrentSelectedRowKeys.current.length) isChangeShow.current = false;
            _selectedRowKeys = tmpCurrentSelectedRowKeys.current;
        }
    } else if (isChangeShow.current) {
        if (!_checkSelectedRowsKeys(_dataGrid)) isChangeShow.current = false;
    }

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <ModalHeader title={Config.lang("Tao_ho_so_nhan_vien")} />
                <ModalBody>
                    <Row>
                        <Loading visible={loading} />
                        <Col xs={12} sm={minimum ? 1 : 4} className={classes.leftBox}>
                            <Divider className={classes.divider} />
                            <div
                                className={`display_row align-center ${minimum ? "valign-middle" : "align-between"}`}
                                style={{
                                    backgroundColor: colors.fill["header-datagrid"],
                                    height: spacing(10),
                                    padding: spacing([0, !minimum ? 4 : 1]),
                                    position: "absolute",
                                    left: spacing(-1),
                                    top: spacing(4),
                                    width: "100%",
                                }}
                            >
                                <div className={"display_row align-center"} style={{ gap: spacing(2) }}>
                                    <ButtonIcon
                                        color={"primary"}
                                        name={"FilterFilled"}
                                        viewType={"ghost"}
                                        onClick={() => setMinimum(!minimum)}
                                    />
                                    {!minimum && (
                                        <Typography className={classes.noWrap}>
                                            {Config.lang("Tieu_chi_loc")}
                                        </Typography>
                                    )}
                                </div>
                                {!minimum && (
                                    <ButtonIcon
                                        viewType={"ghost"}
                                        name={"ArrowLeft"}
                                        onClick={() => setMinimum(!minimum)}
                                    />
                                )}
                            </div>
                            {!minimum && (
                                <Row style={{ marginTop: spacing(10) }}>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Checkbox
                                            labelProps={{
                                                className: classes.noWrap,
                                            }}
                                            checked={isShowSelected}
                                            label={`${Config.lang("Hien_thi_du_lieu_da_chon")}(${
                                                tmpSelectedRowKeys.current?.length ?? 0
                                            })`}
                                            color={"primary"}
                                            onChange={changeTypeShow}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataCboEmployee.rows}
                                            total={dataCboEmployee.total}
                                            skip={filterCboEmployees.current.skip}
                                            limit={filterCboEmployees.current.limit}
                                            defaultValue={filterSearch.current?.EmployeeID}
                                            loading={cboEmployeeLoading}
                                            valueExpr={"EmployeeID"}
                                            renderSelectedItem={"EmployeeName"}
                                            iconExpr={"UserPictureURL"}
                                            label={Config.lang("Nhan_vien")}
                                            placeholder={Config.lang("Chon")}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            onChange={e => filterChange("EmployeeID", e)}
                                            onInput={e => {
                                                filterCboEmployees.current.search = e.target.value;
                                                filterCboEmployees.current.skip = 0;
                                                loadCboEmployees(true);
                                            }}
                                            onLoadMore={e => {
                                                filterCboEmployees.current.skip = e.skip;
                                                filterCboEmployees.current.limit = e.limit;
                                                loadCboEmployees();
                                            }}
                                        />
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            dataSource={dataOrg}
                                            valueExpr={"OrgChartID"}
                                            loading={cboOrgLoading}
                                            displayExpr={"OrgName"}
                                            label={Config.lang("Co_cau_to_chuc")}
                                            keyExpr={"OrgName"}
                                            treeViewParentID={"OrgChartParentID"}
                                            treeViewID={"OrgChartID"}
                                            itemMode={"treeview"}
                                            value={filterSearch.current.OrgChartID}
                                            placeholder={Config.lang("Co_cau_to_chuc")}
                                        />
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            allowSearch
                                            clearAble
                                            dataSource={dataProject?.rows || []}
                                            total={dataProject?.total}
                                            displayExpr={"{ProjectID} - {ProjectName}"}
                                            keyExpr={"ProjectName"}
                                            valueExpr={"ProjectID"}
                                            // multiple
                                            loading={cboProjectLoading}
                                            label={Config.lang("Du_an")}
                                            placeholder={Config.lang("Du_an")}
                                            onChange={e => filterChange("ProjectID", e)}
                                            onInput={e => {
                                                filterCboProjects.current.search = e.target.value;
                                                filterCboProjects.current.skip = 0;
                                                loadCboProjects(true);
                                            }}
                                            onLoadMore={e => {
                                                filterCboProjects.current.skip = e.skip;
                                                filterCboProjects.current.limit = e.limit;
                                                loadCboProjects();
                                            }}
                                        />
                                    </Col>

                                    <Col xs={12}>
                                        <DateRangePicker
                                            label={Config.lang("Ngay_vao_lam")}
                                            clearAble
                                            displayFormat={"DD/MM/YYYY"}
                                            placeholder={"DD/MM/YYYY"}
                                            returnFormat={"YYYY-MM-DD"}
                                            defaultValue={[
                                                filterSearch.current?.DateJoinFrom,
                                                filterSearch.current?.DateJoinTo,
                                            ]}
                                            controls
                                            onChange={e => filterChange("DateJoin", e, "Date")}
                                        />
                                    </Col>

                                    <Col xs={12}>
                                        <DateRangePicker
                                            label={Config.lang("Ngay_sinh")}
                                            clearAble
                                            displayFormat={"DD/MM/YYYY"}
                                            placeholder={"DD/MM/YYYY"}
                                            returnFormat={"YYYY-MM-DD"}
                                            defaultValue={[
                                                filterSearch.current?.BirthDateFrom,
                                                filterSearch.current?.BirthDateTo,
                                            ]}
                                            controls
                                            onChange={e => filterChange("BirthDate", e, "Date")}
                                        />
                                    </Col>

                                    <Col xs={12} className={"display_row align_center valign-bottom mgt4x"}>
                                        <Button
                                            startIcon={"search"}
                                            text={Config.lang("Tim_kiem")}
                                            viewType="outlined"
                                            color={"primary"}
                                            onClick={search}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        <Col xs={12} sm={minimum ? 11 : 8} className={classes.animation}>
                            {!!showRepport && (
                                <ChooseModal
                                    open={!!showRepport}
                                    keyExpr={"ReportID"}
                                    selected={[...dataGrid.rows.find(i => i.EmployeeID === showRepport)?.ReportID]}
                                    dataSource={dataTemplate}
                                    title={Config.lang("Chon_mau_bao_cao")}
                                    onClose={() => setShowReport("")}
                                    zIndex={zIndex(1)}
                                    onChoosed={onChoosedReport}
                                >
                                    <Column
                                        dataField={"ReportID"}
                                        caption={Config.lang("Ma_mau_bao_cao")}
                                        width={150}
                                    />
                                    <Column
                                        dataField={"ReportName"}
                                        caption={Config.lang("Ten_mau_bao_cao")}
                                        minWidth={200}
                                    />
                                </ChooseModal>
                            )}
                            <GridContainer
                                reference={ref => (gridRef.current = ref)}
                                dataSource={_dataGrid || []}
                                totalItems={dataGrid?.total}
                                keyExpr={"EmployeeID"}
                                pagerFullScreen={false}
                                typePaging={isShowSelected ? "normal" : "remote"}
                                itemPerPage={filterSearch.current.limit}
                                skipPerPage={filterSearch.current.skip}
                                onChangePage={handleChangePage}
                                onChangePerPage={handleChangePerPage}
                                showBorders={false}
                                columnAutoWidth={true}
                                height={Config.getHeightGrid() - 100}
                                selection={{
                                    allowSelectAll: true,
                                    mode: "multiple",
                                    selectAllMode: "allPages",
                                    showCheckBoxesMode: "always",
                                }}
                                onSelectionChanged={onSelectionChanged}
                                allowColumnResizing={true}
                                onCellSelectionChanged={e => {
                                    if (e.selectedRange && e.selectedRange.rowType !== "header") {
                                        selectedRange.current = e.selectedRange;
                                    }
                                }}
                                selectedRowKey={_selectedRowKeys}
                            >
                                <Column
                                    caption={Config.lang("nhan_vien")}
                                    dataField={"EmployeeName"}
                                    alignment={"left"}
                                    cellRender={e => `${e.data.EmployeeID} - ${e.data.EmployeeName}`}
                                    width={220}
                                />
                                <Column
                                    caption={Config.lang("Ngay_sinh")}
                                    dataField={"Birthdate"}
                                    alignment={"center"}
                                    dataType={"date"}
                                    format={"dd/MM/yyyy"}
                                    width={150}
                                />
                                <Column
                                    caption={Config.lang("Ngay_vao_lam")}
                                    dataField={"DateJoined"}
                                    alignment={"center"}
                                    dataType={"date"}
                                    format={"dd/MM/yyyy"}
                                    width={150}
                                />
                                <Column
                                    caption={Config.lang("Mau_bao_cao")}
                                    dataField={"ReportID"}
                                    alignment={"center"}
                                    width={200}
                                    cellRender={e => (
                                        <div className={"display_row align-center valign-middle"}>
                                            {!e.data.ReportID?.length ? (
                                                <ButtonIcon
                                                    viewType={"text"}
                                                    circular
                                                    name={"Plus"}
                                                    color={"primary"}
                                                    disabled={loading}
                                                    onClick={() => setShowReport(e.data.EmployeeID)}
                                                />
                                            ) : (
                                                <Typography onClick={() => setShowReport(e.data.EmployeeID)}>
                                                    {e.data.ReportID?.join(", ")}
                                                </Typography>
                                            )}
                                        </div>
                                    )}
                                    allowSorting={false}
                                    headerCellRender={renderHeadClickColumn}
                                />
                                <Column
                                    dataField={"Reports"}
                                    visible={false}
                                />
                                <Column
                                    caption={Config.lang("Dinh_kem")}
                                    dataField={"Attachments"}
                                    width={220}
                                    cellRender={e => {
                                        const { data } = e;
                                        const att = data.Attachments?.[0];
                                        return (
                                            <div className={"display_row align-center"}>
                                                {att && (
                                                    <Chip
                                                        style={{ maxWidth: "50%" }}
                                                        startIcon={renderIcon(att?.name)}
                                                        label={att?.name}
                                                        className={"mgr1x"}
                                                        endIcon={
                                                            <ButtonIcon
                                                                name={"Close"}
                                                                viewType={"ghost"}
                                                                disabled={loading}
                                                                onClick={() => removeAtt(0, data)}
                                                            />
                                                        }
                                                    />
                                                )}
                                                {data.Attachments?.length > 1 && (
                                                    <Chip
                                                        ref={refs => (chipRef.current[data.EmployeeID] = refs)}
                                                        label={`+${data.Attachments.length - 1}`}
                                                        onClick={() => {
                                                            setPopoverAttachment({
                                                                EmployeeID: data.EmployeeID,
                                                                attachments: data.Attachments,
                                                            });
                                                        }}
                                                    />
                                                )}
                                                <ButtonIcon
                                                    name={"Attachment"}
                                                    circular
                                                    viewType={"text"}
                                                    color={"primary"}
                                                    onClick={() => {
                                                        // open chọn file
                                                        attachmentRef.current[data?.EmployeeID].click();
                                                    }}
                                                />
                                                <input
                                                    multiple
                                                    className={"hide"}
                                                    ref={refs =>
                                                        (attachmentRef.current[data?.EmployeeID] = refs)
                                                    }
                                                    type={"file"}
                                                    onChange={att => onChangeAttachment(att, data, e)}
                                                />
                                            </div>
                                        );
                                    }}
                                />
                                <Column
                                    width={160}
                                    alignment="center"
                                    caption={Config.lang("Xuat_word")}
                                    dataField={"IsWord"}
                                    cellRender={e => renderCheckbox(e, "IsWord")}
                                    headerCellRender={renderHeadClickColumn}
                                    allowSorting={false}
                                    allowEditing={true}
                                />
                                <Column
                                    width={160}
                                    alignment="center"
                                    caption={Config.lang("Xuat_excel")}
                                    dataField={"IsExcel"}
                                    cellRender={e => renderCheckbox(e, "IsExcel")}
                                    headerCellRender={renderHeadClickColumn}
                                    allowSorting={false}
                                />
                                <Column
                                    width={160}
                                    alignment="center"
                                    caption={Config.lang("Xuat_pdf")}
                                    dataField={"IsPDF"}
                                    cellRender={e => renderCheckbox(e, "IsPDF")}
                                    headerCellRender={renderHeadClickColumn}
                                    allowSorting={false}
                                />
                            </GridContainer>
                            <Popover
                                ref={popoverRef}
                                width={400}
                                anchor={chipRef.current[popoverAttachment?.EmployeeID] || null}
                                onClose={() => {
                                    setPopoverAttachment({});
                                }}
                            >
                                <PopoverBody
                                    className={"display_row flex-wrap"}
                                    style={{ gap: spacing(), flexDirection: "row" }}
                                >
                                    {popoverAttachment?.attachments?.map((att, idx) => {
                                        if (!idx) return null;
                                        return (
                                            <Chip
                                                key={idx}
                                                startIcon={renderIcon(att.name)}
                                                label={att.name}
                                                endIcon={
                                                    <ButtonIcon
                                                        name={"Close"}
                                                        disabled={loading}
                                                        viewType={"ghost"}
                                                        onClick={() => removeAtt(idx)}
                                                    />
                                                }
                                            />
                                        );
                                    })}
                                </PopoverBody>
                            </Popover>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className={"display_row valign-end"}>
                    <Button
                        startIcon={"Save"}
                        label={Config.lang("Luu")}
                        viewType={"filled"}
                        disabled={loading}
                        color={"info"}
                        onClick={confirmSave}
                    />
                </ModalFooter>
            </Modal>
        </>
    );
};

W09F1121.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,
    loadParams: PropTypes.object,

    /**
     * ProjectID: ""
     * DutyID: ""
     * ...
     * dataSelected: [{{EmployeeID: "", EmployeeName: "",...},{EmployeeID: "", EmployeeName: "",...}]
     * selectedRowKeys: [EmpID, EmpID]
     * isLoaded: false
     */
    dataDefaults: PropTypes.object,

    onClose: PropTypes.func,
    onChosen: PropTypes.func,
};

export default compose(
    connect(
        state => ({
            getCboStatusWork: state.general.getCboStatusWork,
            getCboProjects: state.general.getCboProjects,
            getCboDuty: state.general.getCboDuty2,
        }),
        dispatch => ({
            W09F1121Actions: bindActionCreators(W09F1121Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    )
)(W09F1121);
