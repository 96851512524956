/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 1/3/2023
 * @Example
 */

import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F1120Actions from "../../../../redux/W0X/W09F1120/W09F1120_actions";
import {
    Button,
    Col,
    Row,
    ButtonIcon,
    Typography,
    Popover,
    ButtonMore,
    Modal,
    ModalHeader,
    ModalBody,
    TextInput,
    PagingInfo,
    Image,
    Breadcrumb,
    Icon,
} from "diginet-core-ui/components";
import { makeStyles } from "diginet-core-ui/theme";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import GridActionBar from "../../../grid-container/grid-actionbar";
import History from "../../../libs/history";
import { Loading } from "../../../common/loading/loading";
import W09F1121 from "../W09F1121/W09F1121";
import FileViewer from "../../../common/fileviewer/fileviewer";
import Filter from "../../../filter/filter";
import Bg from "../../../../assets/images/W09F1120/bg.jpeg";

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: theme.colors.white,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
        borderRadius: theme.spacing(1),
        "& .W09F1120-ButtonMore": {
            display: "none",
        },
        "&:hover": {
            backgroundColor: `${theme.colors.fill.hover}`,
            "& .W09F1120-ButtonMore": {
                display: "block",
            },
        },
    },
    heightDefaultIcon: {
        height: theme.spacing(6),
    },
    pdb14x: {
        paddingBottom: theme.spacing(14),
    },
    overFlowScroll: {
        overflow: "auto",
        height: "calc(100vh - 180px)",
    },
    bgPublish: {
        "&::before": {
            position: "absolute",
            backgroundImage: `url(${Bg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            content: '""',
            width: "100%",
            opacity: 0.25,
            height: "100%",
            top: 0,
            left: 0,
            zIndex: -1,
        },
    },
    iconCore: {
        width: "100%",
        height: 224,
    },
}));

const W09F1120 = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [iPermission, setIPermission] = useState();
    const [dataGrid, setDataGrid] = useState({ rows: [], total: 0 });
    const [dataTypeFolder, setDataTypeFolder] = useState([]);
    const [dataTypeFile, setDataTypeFile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingGrid, setIsLoadingGrid] = useState(false);
    const [isShowPopupAddNewFolderName, setShowPopupAddNewFolderName] = useState(false);
    const [isShowPopupChangeFolderName, setShowPopupChangeFolderName] = useState(false);
    const [dataNewFolderName, setDataNewFolderName] = useState("");
    const [dataReNewFolderName, setDataReNameFolder] = useState("");
    const [idFolderName, setIdFolderName] = useState("");
    const [modeViewFolder, setModeViewFolder] = useState("ModuleView");
    const [isShowW09F1121, setIsShowW09F1121] = useState(false);
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [isShowFileViewer, setIsShowFileViewer] = useState(false);
    const [dataFileViewer, setDataFileViewer] = useState({});
    const [dataAncestor, setDataAncestor] = useState({ ancestors: [], parent: null });

    const refFile = useRef({});
    const refBtnFile = useRef({});
    const refWrapperButtonMoreFile = useRef({});
    const refFolder = useRef({});
    const refBtnFolder = useRef({});
    const refWrapperButtonMoreFolder = useRef({});
    const refPopover = useRef(null);
    const filterGrid = useRef({ skip: 0, limit: 20 });
    const oldDataFolderName = useRef([]);
    const isSubFolder = useRef(false);
    const isPublish = useRef(Config.getUrlParams(props)?.id);

    const loadForm = (isChangedName = false) => {
        const params = {
            Name: null,
            skip: filterGrid.current?.skip,
            limit: filterGrid.current?.limit,
            // params publish
            id: isPublish.current,
        };
        setIsLoading(true);

        const api = isPublish.current ? "share" : "loadForm";
        dispatch(
            W09F1120Actions[api](params, (error, data) => {
                setIsLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                const rows = data?.rows || [];
                const total = data?.total || 0;

                if (rows.length && isPublish.current && !breadcrumbList.length) {
                    handleBreadCrumb({ Name: rows[0].parent, id: isPublish.current });
                }

                rows.sort((a, b) => {
                    return a.Type === "folder" || b.Type === "folder" ? -1 : 1;
                });

                oldDataFolderName.current = rows;
                const listTypeFolder = rows.filter(d => d.Type === "folder");
                const listTypeFile = rows.filter(d => d.Type === "file" || d.Type === "attachment");

                setDataTypeFolder(listTypeFolder);
                setDataTypeFile(listTypeFile);
                setDataGrid({ rows: rows, total: total });

                if (isChangedName && modeViewFolder === "ListView") {
                    setIsLoadingGrid(true);
                }
            })
        );
    };

    const loadDetailFolder = (isReload = false, listAncestor = [], data = {}) => {
        const ancestors = listAncestor.length > 0 ? listAncestor : dataAncestor?.ancestors || [];
        const params = {
            ancestors: ancestors,
            skip: filterGrid.current.skip,
            limit: filterGrid.current.limit,
            id: data.id,
        };
        setIsLoading(true);

        const api = isPublish.current ? "share" : "loadForm";
        dispatch(
            W09F1120Actions[api](params, (error, data) => {
                setIsLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                const rows = data?.rows || [];
                const total = data?.total || 0;

                rows.sort((a, b) => {
                    return a.Type === "folder" || b.Type === "folder" ? -1 : 1;
                });

                oldDataFolderName.current = rows;

                if (!isReload) {
                    setDataAncestor({
                        ancestors: rows?.[0]?.ancestors || [],
                        parent: rows?.[0]?.parent || null,
                    });
                }

                const listTypeFolder = rows.filter(d => d.Type === "folder");
                const listTypeFile = rows.filter(d => d.Type === "file" || d.Type === "attachment");

                setDataTypeFolder(listTypeFolder);
                setDataTypeFile(listTypeFile);
                setDataGrid({ rows: rows, total: total });
            })
        );
    };

    const createFolder = () => {
        const params = {
            Name: dataNewFolderName,
            ancestors: dataAncestor.ancestors || [],
            parent: dataAncestor.parent || null,
        };
        dispatch(
            W09F1120Actions.createFolder(params, async (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                setShowPopupAddNewFolderName(false);
                await saveHistory(data, 0);
                // nếu đang trong folder con và thêm mới folder thì load api DetailFolder
                isSubFolder.current ? loadDetailFolder(true) : loadForm();
                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
            })
        );
    };

    const changeFolderName = () => {
        const params = {
            id: idFolderName ?? "",
            Name: dataReNewFolderName,
        };
        dispatch(
            W09F1120Actions.changeFolderName(params, async (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                setShowPopupChangeFolderName(false);
                setIdFolderName("");
                await saveHistoryChangeFolderName(data);
                // nếu đang trong folder con và đổi tên folder thì load api DetailFolder
                isSubFolder.current ? loadDetailFolder(true) : loadForm(true);
                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
            })
        );
    };

    const search = (valueSearch = "") => {
        const params = {
            search: valueSearch,
            ancestors: dataAncestor.ancestors || [],
        };
        dispatch(
            W09F1120Actions.search(params, async (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                const rows = data?.rows || [];
                const total = data?.total || 0;

                rows.sort((a, b) => {
                    return a.Type === "folder" || b.Type === "folder" ? -1 : 1;
                });

                const listTypeFolder = rows.filter(d => d.Type === "folder");
                const listTypeFile = rows.filter(d => d.Type === "file" || d.Type === "attachment");

                setDataTypeFolder(listTypeFolder);
                setDataTypeFile(listTypeFile);
                setDataGrid({ rows: rows, total: total });
            })
        );
    };

    useEffect(() => {
        const loadPermission = () => {
            if (isPublish.current) {
                setIPermission(1);
                return;
            }
            dispatch(
                generalActions.getPermission("W09F1120", isPer => {
                    setIPermission(isPer);
                })
            );
        };
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!iPermission) return;
        loadForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    const onEdit = data => {
        if (modeViewFolder === "ModuleView") {
            refBtnFolder.current[data.id].close();
        }
        setIdFolderName(data.id);
        setShowPopupChangeFolderName(true);
    };

    const onDownload = data => {
        const params = {
            id: data.id,
        };
        dispatch(
            W09F1120Actions.download(params, (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (data?.url) {
                    const link = document.createElement("a");
                    link.href = data.url;
                    link.download = data.fileName;
                    link.click();
                    URL.revokeObjectURL(data.url);
                }
            })
        );
    };

    const replaceQuotationMark = text => {
        return text?.replace(/^\/?|\/?$/g, "");
    };

    const onShare = data => {
        const params = {
            id: data.id,
        };
        dispatch(
            W09F1120Actions.publish(params, (error, dataPublish) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
                if (dataPublish?.SharedLinks) {
                    const arrLink = [
                        replaceQuotationMark(window.location.origin),
                        replaceQuotationMark(Config.getRootPath()),
                        replaceQuotationMark(`W09F1120P?id=${data.id}`),
                    ];
                    let linkShare = arrLink.filter(Boolean).join("/");

                    let textarea = document.createElement("textarea");
                    document.body.appendChild(textarea);
                    textarea.value = linkShare;
                    textarea.select();
                    document.execCommand("copy");
                    Config.notify.show("success", "Sao_chep_thanh_cong_duong_dan:" + linkShare);
                    document.body.removeChild(textarea);
                }
            })
        );
    };

    const onDelete = data => {
        const params = {
            id: data.id,
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            dispatch(
                W09F1120Actions.onDelete(params, async (error) => {
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    }
                    await saveHistory(data, 3);
                    // nếu đang trong folder con và đổi tên folder thì load api DetailFolder
                    isSubFolder.current ? loadDetailFolder(true) : loadForm();
                    Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                })
            );
        });
    };

    const saveHistory = async (data, action) => {
        if (data?.id) {
            const _data = {
                Name: data?.Name ?? "",
            };

            const captions = {
                Name: "Ten",
            };

            const options = {
                data: _data,
                captions: captions,
                action: action,
                ModuleID: "D09",
                TransID: data.id,
                TransactionID: "W09F1120",
                TransactionName: Config.lang("Tao_ho_so_nhan_vien"),
            };
            const history = new History(options);

            if (history.get().length > 0) {
                await history.save();
            }
        } else {
            return Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const saveHistoryChangeFolderName = async data => {
        if (data?.id) {
            const dataOld = oldDataFolderName.current.find(d => d.id === data.id);
            const oldName = {
                Name: dataOld?.Name ?? "",
            };
            const _data = {
                Name: data?.Name ?? "",
            };

            const captions = {
                Name: "Ten",
            };

            const options = {
                data: _data,
                captions: captions,
                dataCompare: oldName,
                action: 1,
                ModuleID: "D09",
                TransID: data.id,
                TransactionID: "W09F1120",
                TransactionName: Config.lang("Tao_ho_so_nhan_vien"),
            };
            const history = new History(options);

            if (history.get().length > 0) {
                await history.save();
            }
        } else {
            return Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };

    const handleClickFolder = data => {
        // if (isPublish.current) return;
        filterGrid.current.skip = 0;
        filterGrid.current.limit = 20;

        isSubFolder.current = true;
        const listAncestor = dataAncestor.ancestors.concat(data?.Name || "");
        setDataAncestor({ ancestors: listAncestor, parent: data?.Name || null });
        loadDetailFolder(true, listAncestor, data);
        handleBreadCrumb(data);
    };

    const handleBreadCrumb = data => {
        const hasRootBreadCrumb = breadcrumbList.some(d => d.Level === 1);
        const rootBreadCrumb = isPublish.current
            ? []
            : [
                  {
                      label: Config.lang("Ho_so_nhan_vien"),
                      onClick: onBack,
                      Level: 1,
                      Ancestors: [],
                      Parent: null,
                  },
              ];
        const dataBreadCrumb = hasRootBreadCrumb ? [...breadcrumbList] : rootBreadCrumb;
        const paramsHandleClick = {
            Level: dataBreadCrumb.length + 1,
            Parent: data?.Name ? data.Name : null,
            BreadcrumbList: dataBreadCrumb,
            id: data.id,
        };
        dataBreadCrumb.push({
            label: data?.Name ?? "",
            onClick: () => handleClickBreadCrumb(paramsHandleClick),
            icon: "FolderPerson",
            Level: dataBreadCrumb.length + 1,
            Ancestors: data?.Name ? [data.Name] : [],
            Parent: data?.Name ? data.Name : null,
        });

        setBreadcrumbList(dataBreadCrumb);
    };

    const renderFolder = d => {
        return (
            <div ref={ref => (refFolder.current[d.id] = ref)} className={` ${classes.card}`}>
                <div className={"display_row align-center align-between"}>
                    <div
                        onClick={() => handleClickFolder(d)}
                        style={{ cursor: "pointer", flex: 1 }}
                        className={"pdt4x pdb4x pdl4x display_row align-center"}
                    >
                        <ButtonIcon className={"mgr2x"} name={"FolderPerson"} viewType={"ghost"} />
                        <Typography hoverTooltip lineClamp={1}>
                            {d.Name}
                        </Typography>
                    </div>

                    <div
                        className={classes.heightDefaultIcon}
                        ref={ref => (refWrapperButtonMoreFolder.current[d.id] = ref)}
                    >
                        <ButtonMore
                            popoverProp={{
                                transformOrigin: { horizontal: "right", vertical: "top" },
                                ref: ref => (refBtnFolder.current[d.id] = ref),
                            }}
                            buttonProps={{
                                viewType: "ghost",
                                onClick: () => {
                                    refBtnFolder.current[d.id].setPosition(refWrapperButtonMoreFolder.current[d.id]);
                                    refBtnFolder.current[d.id].show();
                                },
                            }}
                            optionType="icon"
                            className={"mgr4x W09F1120-ButtonMore"}
                            labelProp={{ hoverTooltip: true }}
                            options={[
                                {
                                    action: () => onEdit(d),
                                    icon: "Edit",
                                    disabled: iPermission <= 2,
                                    label: Config.lang("Doi_ten"),
                                    hidden: isPublish.current,
                                },
                                {
                                    action: () => onDownload(d),
                                    icon: "Download",
                                    disabled: iPermission <= 0,
                                    label: Config.lang("Tai_xuong"),
                                },
                                {
                                    action: () => onShare(d),
                                    icon: "Share",
                                    disabled: iPermission <= 0,
                                    label: Config.lang("Chia_se"),
                                    hidden: isPublish.current,
                                },
                                {
                                    action: () => onDelete(d),
                                    icon: "Delete",
                                    disabled: iPermission <= 3,
                                    label: Config.lang("Xoa"),
                                    hidden: isPublish.current,
                                },
                            ].filter(i => !i.hidden)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const getIcon = data => {
        let icon = "Another";
        let fileName = data?.Name?.split(".") || "";
        fileName = fileName[fileName.length - 1];

        switch (fileName) {
            case "xlsx":
            case "xls":
                icon = "Sheet";
                break;
            case "docx":
            case "doc":
            case "txt":
                icon = "Word";
                break;
            case "pdf":
                icon = "Pdf";
                break;
            case "ppt":
            case "pptx":
                icon = "Presentation";
                break;
            case "jpeg":
            case "jpg":
            case "png":
            case "gif":
            case "bmp":
                icon = "Image";
                break;
            default:
                break;
        }
        return { icon };
    };

    const renderFile = d => {
        const { icon } = getIcon(d);
        let srcImg = null;
        if(icon !== "Image" && d?.Thumbnail) {
            srcImg = d?.Thumbnail;
        }
        if(icon === "Image" && d?.LinkCDN) {
            srcImg = d?.LinkCDN;
        }
        return (
            <div ref={ref => (refFile.current[d.id] = ref)} className={classes.card}>
                <div
                    style={{ cursor: "pointer", height: 224 }}
                    onClick={() => {
                        setDataFileViewer(d);
                        setIsShowFileViewer(true);
                    }}
                >
                    {srcImg ? (
                        <Image width={"100%"} height={"100%"} src={Config.getCDNPath(srcImg)} />
                    ) : (
                        <Icon
                            className={classes.iconCore}
                            name={icon}
                            width={104}
                            height={104}
                        />
                    )}
                </div>
                <div className={"display_row align-center align-between"}>
                    <div
                        onClick={() => {
                            setDataFileViewer(d);
                            setIsShowFileViewer(true);
                        }}
                        style={{ cursor: "pointer", flex: 1 }}
                        className={"pdt4x pdb4x pdl4x display_row align-center"}
                    >
                        <ButtonIcon className={"mgr2x"} size={"medium"} viewType={"ghost"} name={icon} />
                        <Typography hoverTooltip lineClamp={1}>
                            {d.Name}
                        </Typography>
                    </div>
                    <div
                        className={classes.heightDefaultIcon}
                        ref={ref => (refWrapperButtonMoreFile.current[d.id] = ref)}
                    >
                        <ButtonMore
                            popoverProp={{
                                transformOrigin: { horizontal: "right", vertical: "top" },
                                ref: ref => (refBtnFile.current[d.id] = ref),
                            }}
                            buttonProps={{
                                viewType: "ghost",
                                onClick: () => {
                                    refBtnFile.current[d.id].setPosition(refWrapperButtonMoreFile.current[d.id]);
                                    refBtnFile.current[d.id].show();
                                },
                            }}
                            optionType="icon"
                            className={"mgr4x W09F1120-ButtonMore"}
                            labelProp={{ hoverTooltip: true }}
                            options={[
                                {
                                    action: () => onDownload(d),
                                    icon: "Download",
                                    disabled: iPermission <= 0,
                                    label: Config.lang("Tai_xuong"),
                                },
                                {
                                    action: () => onShare(d),
                                    icon: "Share",
                                    disabled: iPermission <= 0,
                                    label: Config.lang("Chia_se"),
                                    hidden: isPublish.current,
                                },
                                {
                                    action: () => onDelete(d),
                                    icon: "Delete",
                                    disabled: iPermission <= 3,
                                    label: Config.lang("Xoa"),
                                    hidden: isPublish.current,
                                },
                            ].filter(i => !i.hidden)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderFolderModuleView = () => {
        return (
            <>
                <Col xs={12}>
                    {dataTypeFolder.length > 0 && (
                        <>
                            <Typography className={"mgb4x"}>{Config.lang("Thu_muc")}</Typography>
                            <Row className={"mgb6x"}>
                                {dataTypeFolder?.map(d => (
                                    <Col key={d.id} xs={12} sm={6} md={4} lg={2}>
                                        {renderFolder(d)}
                                    </Col>
                                ))}
                            </Row>
                        </>
                    )}
                    {dataTypeFile.length > 0 && (
                        <>
                            <Typography className={"mgb4x"}>{Config.lang("Tep")}</Typography>
                            <Row className={classes.pdb14x}>
                                {dataTypeFile.length > 0 &&
                                    dataTypeFile.map(d => (
                                        <Col key={d.id} xs={12} sm={6} md={4} lg={2}>
                                            {renderFile(d)}
                                        </Col>
                                    ))}
                            </Row>
                        </>
                    )}
                </Col>
            </>
        );
    };

    const cellRenderAction = e => {
        if (!e) return null;
        const { data } = e?.row || {};
        return (
            <GridActionBar>
                {!isPublish.current && data.Type !== "file" && (
                    <ButtonIcon
                        circular
                        viewType={"text"}
                        className={"mgr1x"}
                        name={"Edit"}
                        disabled={iPermission <= 2}
                        onClick={() => onEdit(data)}
                    />
                )}

                <ButtonIcon
                    circular
                    viewType={"text"}
                    className={"mgr1x"}
                    name={"Download"}
                    disabled={iPermission <= 0}
                    onClick={() => onDownload(data)}
                />
                {!isPublish.current && (
                    <ButtonIcon
                        circular
                        viewType={"text"}
                        className={"mgr1x"}
                        name={"Share"}
                        disabled={iPermission <= 0}
                        onClick={() => onShare(data)}
                    />
                )}
                {!isPublish.current && (
                    <ButtonIcon
                        circular
                        viewType={"text"}
                        name={"Delete"}
                        disabled={iPermission <= 3}
                        onClick={() => onDelete(data)}
                    />
                )}
            </GridActionBar>
        );
    };

    const onChangePage = page => {
        filterGrid.current.skip = page * filterGrid.current.limit;
        loadForm();
    };

    const onChangePerPage = per => {
        filterGrid.current.skip = 0;
        filterGrid.current.limit = per;
        loadForm();
    };

    const renderListView = useMemo(() => {
        return (
            <Col xs={12}>
                <GridContainer
                    dataSource={dataGrid?.rows || []}
                    keyExpr={"id"}
                    itemPerPage={filterGrid.current.limit}
                    skipPerPage={filterGrid.current.skip}
                    rowAlternationEnabled={true}
                    totalItems={dataGrid?.total || 0}
                    hoverStateEnabled={true}
                    typePaging={"remote"}
                    height={Config.getHeightGrid() - 20}
                    onChangePage={onChangePage}
                    onChangePerPage={onChangePerPage}
                    onContentReady={() => setIsLoadingGrid(false)}
                    onCellClick={e => {
                        if (!e) return null;
                        const { data } = e?.row || {};
                        if (e.column.dataField !== "Action") {
                            if (data.Type === "folder") {
                                handleClickFolder(data);
                            }
                            if (data.Type === "file" || data.Type === "attachment") {
                                setIsShowFileViewer(true);
                                setDataFileViewer(data);
                            }
                        }
                    }}
                >
                    <Column
                        caption={Config.lang("Ten").toUpperCase()}
                        cellRender={e => {
                            if (!e) return null;
                            const { data } = e?.row || {};
                            if (data.Type === "folder") {
                                return (
                                    <div className={"display_row align-center"}>
                                        <ButtonIcon
                                            className={"mgr2x"}
                                            size={"medium"}
                                            viewType={"ghost"}
                                            name={"FolderPerson"}
                                        />
                                        <Typography>{data?.Name ?? ""}</Typography>
                                    </div>
                                );
                            }
                            if (data.Type === "file" || data.Type === "attachment") {
                                const { icon } = getIcon(data);
                                return (
                                    <div className={"display_row align-center"}>
                                        <ButtonIcon
                                            className={"mgr2x"}
                                            size={"medium"}
                                            viewType={"ghost"}
                                            name={icon}
                                        />
                                        <Typography>{data?.Name ?? ""}</Typography>
                                    </div>
                                );
                            }
                        }}
                    />
                    <Column
                        dataField={"Action"}
                        alignment={"center"}
                        caption={" "}
                        allowEditing={false}
                        fixed={true}
                        fixedPosition={"right"}
                        width={200}
                        cellRender={cellRenderAction}
                    />
                </GridContainer>
            </Col>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, modeViewFolder, isLoadingGrid]);

    const renderModel = ({ isOpen, setShowPopup, setFolderName, mode, action }) => {
        return (
            <Modal open={isOpen} width={"70%"} onClose={() => setShowPopup(false)}>
                <ModalHeader title={mode === "add" ? Config.lang("Them_muc_moi") : Config.lang("Doi_ten")} />
                <ModalBody>
                    <TextInput
                        onChange={e => setFolderName(e.target.value)}
                        label={mode === "add" ? Config.lang("Dat_ten_thu_muc") : Config.lang("Nhap_ten_moi")}
                    />
                    <div className={"display_row valign-bottom"}>
                        <Button
                            startIcon={mode === "add" ? "AddFilled" : "Save"}
                            color={"info"}
                            viewType={"filled"}
                            onClick={action}
                            label={mode === "add" ? Config.lang("Tao") : Config.lang("Luu")}
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                dropdownProps={{
                    endIcon: "",
                }}
                isUseDDCore={true}
                placeholder={Config.lang("Tim_kiem")}
                onTextChanged={e => {
                    if (!e) return null;
                    search(e.target.value);
                }}
            />
        );
    };

    const onCloseW09F1121 = () => {
        // nếu đang trong folder con và đổi tên folder thì load api DetailFolder
        isSubFolder.current ? loadDetailFolder(true) : loadForm();
        setIsShowW09F1121(false);
    };

    const handleClickBreadCrumb = ({ Level, Parent, BreadcrumbList, id }) => {
        const sliceBreadCrumbList = BreadcrumbList.slice(0, Level);
        let listAncestor = [];
        BreadcrumbList.forEach(d => {
            if (d.hasOwnProperty("Ancestors") && d.Ancestors.length > 0) {
                listAncestor.push(d.Ancestors[0]);
            }
        });
        setBreadcrumbList(sliceBreadCrumbList);
        setDataAncestor({ ancestors: listAncestor, parent: Parent || null });
        loadDetailFolder(true, listAncestor, { id: id });
    };

    const onBack = () => {
        filterGrid.current.skip = 0;
        filterGrid.current.limit = 20;
        isSubFolder.current = false;
        setModeViewFolder("ModuleView");
        setDataTypeFolder([]);
        setDataTypeFile([]);
        setBreadcrumbList([]);
        setDataAncestor({ ancestors: [], parent: null });
        loadForm();
    };
    return (
        <div className={isPublish.current && `pd4x ${classes.bgPublish}`}>
            <Loading visible={isLoading || isLoadingGrid} />
            {isShowW09F1121 && (
                <W09F1121 open={isShowW09F1121} Ancestors={dataAncestor?.ancestors || []} onClose={onCloseW09F1121} />
            )}
            {isShowFileViewer && (
                <Modal
                    width={"70%"}
                    open={isShowFileViewer}
                    onClose={() => {
                        setIsShowFileViewer(false);
                        setDataFileViewer({});
                    }}
                >
                    <ModalHeader title={dataFileViewer?.Name?.split(".")[0] || ""} />
                    <ModalBody style={{ height: 800 }}>
                        <FileViewer
                            fileName={dataFileViewer?.Name?.split(".")[0] || ""}
                            filePath={Config.getCDNPath(dataFileViewer?.LinkCDN || "")}
                            fileType={dataFileViewer?.Name?.split(".").pop() || ""}
                            height={"100%"}
                        />
                    </ModalBody>
                </Modal>
            )}
            {isShowPopupAddNewFolderName &&
                renderModel({
                    mode: "add",
                    isOpen: isShowPopupAddNewFolderName,
                    setShowPopup: setShowPopupAddNewFolderName,
                    setFolderName: setDataNewFolderName,
                    action: createFolder,
                })}
            {isShowPopupChangeFolderName &&
                renderModel({
                    mode: "edit",
                    isOpen: isShowPopupChangeFolderName,
                    setShowPopup: setShowPopupChangeFolderName,
                    setFolderName: setDataReNameFolder,
                    action: changeFolderName,
                })}

            {/*Header publish page*/}
            {isPublish.current && (
                <ActionToolbar
                    style={{ width: "100%", maxWidth: "100%" }}
                    title={Config.lang("Tai_lieu_duoc_chia_se")}
                    alignment={"flex-end"}
                >
                    <div className={"display_row align-center"}>
                        <ButtonIcon
                            onClick={() => {
                                const modeView = modeViewFolder === "ModuleView" ? "ListView" : "ModuleView";
                                if (modeView === "ListView") {
                                    setIsLoadingGrid(true);
                                }
                                setModeViewFolder(modeView);
                            }}
                            name={modeViewFolder}
                            viewType={"ghost"}
                        />
                    </div>
                </ActionToolbar>
            )}

            {/*Header private page*/}
            {!isPublish.current && (
                <>
                    <div className={"hidden"}>{renderFilter()}</div>
                    <ActionToolbar alignment={"space-between"} title={Config.lang("Ho_so_nhan_vien")}>
                        <Popover
                            ref={ref => (refPopover.current = ref)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{ horizontal: "left", vertical: "bottom" }}
                            anchor={
                                <Button
                                    label={Config.lang("Tao_moi")}
                                    color={"primary"}
                                    viewType={"filled"}
                                    startIcon={"AddFilled"}
                                />
                            }
                        >
                            <Button
                                startIcon={"Person"}
                                disabled={iPermission <= 1}
                                label={Config.lang("Ho_so_nhan_vien")}
                                onClick={() => {
                                    refPopover.current.close();
                                    setIsShowW09F1121(true);
                                }}
                            />
                            <Button
                                style={{ display: "flex", justifyContent: "flex-start" }}
                                startIcon={"FolderAdd"}
                                label={Config.lang("Thu_muc_moi")}
                                disabled={iPermission <= 1}
                                onClick={() => {
                                    refPopover.current.close();
                                    setShowPopupAddNewFolderName(true);
                                }}
                            />
                        </Popover>

                        <div className={"display_row align-center"}>
                            <ButtonIcon
                                onClick={() => {
                                    const modeView = modeViewFolder === "ModuleView" ? "ListView" : "ModuleView";
                                    if (modeView === "ListView") {
                                        setIsLoadingGrid(true);
                                    }
                                    setModeViewFolder(modeView);
                                }}
                                name={modeViewFolder}
                                viewType={"ghost"}
                            />
                        </div>
                    </ActionToolbar>
                </>
            )}

            {breadcrumbList.length > 0 && (
                <Breadcrumb color={"primary"} className={"mgt4x mgb4x"} data={breadcrumbList} />
            )}

            <div className={`${modeViewFolder === "ModuleView" ? "mgt6x" : "mgt4x"}`}>
                <div className={modeViewFolder === "ModuleView" ? classes.overFlowScroll : ""}>
                    <Row>{modeViewFolder === "ModuleView" ? renderFolderModuleView() : renderListView}</Row>
                </div>
                {modeViewFolder === "ModuleView" && (
                    <PagingInfo
                        className={"paging paging-fixed"}
                        onChangePage={page => onChangePage(page)}
                        onChangePerPage={page => onChangePerPage(page)}
                        totalItems={dataGrid.total}
                        itemsPerPage={filterGrid.current.limit}
                        listPerPage={[10, 20, 40, 50]}
                    />
                )}
            </div>
        </div>
    );
};

export default W09F1120;
